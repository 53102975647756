@import "01_setting/_var.scss";
@import "01_setting/_mixin.scss";
/*=============================================================
 home トップページ
=============================================================*/

/*service
-------------------------------------------------------------*/
.secService{ 
	background-color: #f6faf5;
	padding: 40px 0;
	.head{ 
		padding-top: 40px;
		background: url(../img/home/ico_tit_service01.png) 50% 0 no-repeat;
		background-size: 43px auto;
	}
	.read{ 
		text-align: center;
		font-size: 1.6rem;
		line-height: 1.7;
		padding: 15px 0 25px;
	}
	.list{ 
		margin-bottom: 25px;
		.item{ 
			background-color: #fff;
			+.item{ 
				margin-top: 15px;
			}
			.box{ 
				border-top: 3px solid #257e61;
				padding: 0 20px 20px;
				.titItem{ 
					text-align: center;
					font-size: 1.9rem;
					font-weight: 500;
					color: #257e61;
					border-bottom: 1px solid #d9d8cf;
					padding: 10px 0 8px;
				}
				.txt{ 
					padding-top: 10px;
				}
			}
		}
	}
	.btn{ 
		padding: 0 20px;
		box-sizing: border-box;
	}
	@include mq(pc) { 
		padding: 67px 0 70px;
		.head{ 
			padding-top: 60px;
			background: url(../img/home/ico_tit_service01.png) 50% 0 no-repeat;
			background-size: 61px;
		}
		.read{ 
			font-size: 1.7rem;
			padding: 20px 0 30px;
		}
		.list{ 
			margin-bottom: 30px;
			.item{ 
				margin-bottom: 20px;
				float: left;
				width: 353px;
				margin-right: 20px;
				+.item{ 
					margin-top: 0;
				}
				&:nth-child(3n){ 
					margin-right: 0;
				}
				.box{ 
					padding: 0 30px 30px;
					.titItem{ 
						font-size: 2.3rem;
						padding: 20px 0 12px;
					}
					.txt{ 
						padding-top: 15px;
					}
				}
			}
		}
		.btn{ 
			width: 410px;
			margin: auto;
			padding: 0;
		}
	}
}

/*features
-------------------------------------------------------------*/
.secFeatures{ 
	background-color: #0f6b4d;
	padding: 40px 0;
	.head{ 
		.read{ 
			font-size: 1.7rem;
			background-color: #fff;
			text-align: center;
			border-radius: 25px;
			font-weight: 500;
			padding: 5px 0;
			position: relative;
			width: 275px;
			margin: 0 auto 8px;
			&:before{ 
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 7px 7px 0 7px;
				border-color: #fff transparent transparent transparent;
				position: absolute;
				bottom: -7px;
				left: 0;
				right: 0;
				margin: auto;
			}
		}
		.tit{ 
			text-align: center;
			margin-bottom: 10px;
			.titIn{ 
				color: #fff;
				font-size: 2.9rem;
				font-weight: 500;
				background-color: #0f6b4d;
				.num{ 
					display: inline-block;
					font-size: 5.4rem;
					font-weight: 500;
					line-height: 1.2;
				}
			}
			&:before{ 
				background-color: #579883;
			}
		}
	}
	.list{ 
		.item{ 
			display: table;
			width: 100%;
			+.item{ 
				margin-top: 15px;
			}
			.img{ 
				display: table-cell;
				vertical-align: middle;
				width: 176px;
			}
			.txt{ 
				display: table-cell;
				vertical-align: middle;
				color: #fff;
				padding-left: 15px;
			}
		}
	}
	@include mq(pc) { 
		background: #0f6b4d url(../img/home/bg_service01.png) 100% 0 no-repeat;
		padding: 70px 0 90px;
		.head{ 
			.read{ 
				font-size: 2.1rem;
				padding: 10px 0;
				width: 550px;
				margin-bottom: 10px;
				&:before{ 
					border-width: 10px 10px 0 10px;
					bottom: -10px;
				}
			}
			.tit{ 
				margin-bottom: 25px;
				.titIn{ 
					font-size: 4.0rem;
					.num{ 
						font-size: 9.0rem;
						line-height: 1.1;
					}
				}
			}
		}
		.list{ 
			margin-left: 30px;
			.item{ 
				float: left;
				width: 303px;
				+.item{ 
					margin-top: 0;
					margin-left: 80px;
				}
				.img{ 
					display: block;
					width: 295px;
					margin-left: auto;
					margin-bottom: 5px;
				}
				.txt{ 
					display: block;
					padding-left: 0;
					padding-right: 40px;
				}
			}
		}
	}
}

/*message
-------------------------------------------------------------*/
.secMessage{ 
	box-shadow: 0px 2px 3.92px 0.08px rgba(215, 215, 215, 0.49);
	.img{ 
		margin: 0 -15px;
	}
	.box{ 
		padding: 35px 15px 25px;
		.head{ 
			background: url(../img/home/ico_tit_message01.png) 6px 0 no-repeat;
			background-size: 30px auto;
			padding-left: 50px;
			margin-bottom: 3px;
			.tit{ 
				font-size: 2.3rem;
				line-height: 1;
				margin-bottom: 7px;
				font-weight: 500;
			}
			.eng{ 
				text-align: left;
			}
		}
	}
	@include mq(pc) { 
		box-shadow: none;
		background: url(../img/home/bg_message01.png) 50% 50% no-repeat;
		background-size: cover;
		padding: 80px 0 70px;
		.relative{ 
			position: relative;
		}
		.img{ 
			margin: 0;
			position: absolute;
			bottom: -70px;
			right: -40px;
		}
		.box{ 
			width: 685px;
			background-color: #fff;
			padding: 55px 60px 60px;
			box-sizing: border-box;
			.head{ 
				background: url(../img/home/ico_tit_message01.png) 6px 5px no-repeat;
				background-size: 37px auto;
				padding-left: 60px;
				margin-bottom: 20px;
				.tit{ 
					font-size: 3.2rem;
					margin-bottom: 5px;
				}
			}
		}
	}
}

/*new
-------------------------------------------------------------*/
.secNew{ 
	padding: 35px 0;
	.head{ 
		.tit{ 
			font-size: 2.2rem;
			line-height: 1;
			margin-bottom: 7px;
			font-weight: 500;
		}
		.eng{ 
			text-align: left;
		}
	}
	.list{ 
		margin-bottom: 25px;
		.item{ 
			border-bottom: 1px solid #e0e0e0;
			margin: 0 -15px;
			padding: 0 15px;
			a{ 
				display: block;
				text-decoration: none;
				padding: 18px 0;
				.date{ 
					color: #918674;
					&:before{ 
						content: '';
						background-color: #918674;
						width: 10px;
						height: 10px;
						border-radius: 2px;
						display: inline-block;
						margin-right: 6px;
					}
				}
				.txt{ 
					color: #257e61;
					font-weight: 500;
				}
			}
		}
	}
	.btn{ 
		padding: 0 20px;
		box-sizing: border-box;
	}
	@include mq(pc) { 
		padding: 40px 0;
		.relative{ 
			position: relative;
		}
		.head{ 
			float: left;
			width: 260px;
			padding-top: 25px;
			.tit{ 
				font-size: 3.0rem;
				margin-bottom: 5px;
			}
		}
		.list{ 
			float: right;
			width: 840px;
			margin-bottom: 50px;
			.item{ 
				border-bottom: 1px solid #e0e0e0;
				margin: 0;
				padding: 0;
				a{ 
					padding: 25px 0;
					.date{ 
						float: left;
						width: 130px;
					}
					.txt{ 
						padding-left: 130px;
					}
					&:hover{ 
						.txt{ 
							text-decoration: underline;
						}
					}
				}
			}
		}
		.btn{ 
			width: 410px;
			margin: auto;
			padding: 0;
		}
	}
}

/*column
-------------------------------------------------------------*/
.secColumn{ 
	background-color: #f7fbf6;
	padding: 44px 0;
	.head{ 
		padding-top: 40px;
		background: url(../img/home/ico_tit_column01.png) 50% 0 no-repeat;
		background-size: 48px auto;
		margin-bottom: 20px;
	}
	.list{ 
		margin-bottom: 25px;
		.item{ 
			+.item{ 
				margin-top: 20px;
			}
			.img{ 
				margin: 0 -15px;
			}
			.box{ 
				border-top: 3px solid #257e61;
				box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.3);
				padding: 25px 20px 15px;
				margin-top: -25px;
				position: relative;
				background-color: #fff;
				a{ 
					display: block;
					text-decoration: none;
					.category{ 
						margin-bottom: 10px;
						.date{ 
							color: #918674;
							&:before{ 
								content: '';
								background-color: #918674;
								width: 10px;
								height: 10px;
								border-radius: 2px;
								display: inline-block;
								margin-right: 6px;
							}
						}
						.catName{ 
							display: inline-block;
							min-width: 90px;
							background-color: #257e61;
							color: #fff;
							font-size: 1.3rem;
							font-weight: 500;
							text-align: center;
							margin-left: 10px;
							padding: 2px 5px;
							box-sizing: border-box;
						}
					}
					.titColumn{ 
						font-size: 1.9rem;
						color: #257e61;
						font-weight: 500;
					}
				}
			}
		}
	}
	.btn{ 
		padding: 0 20px;
		box-sizing: border-box;
	}
	@include mq(pc) { 
		padding: 80px 0 70px;
		.head{ 
			padding-top: 50px;
			background: url(../img/home/ico_tit_column01.png) 50% 0 no-repeat;
			background-size: 57px auto;
			margin-bottom: 40px;
		}
		.list{ 
			margin-bottom: 20px;
			.item{ 
				margin-bottom: 30px;
				float: left;
				width: 536px;
				+.item{ 
					margin-top: 0;
				}
				&:nth-child(even){ 
					float: right;
				}
				.img{ 
					margin: 0;
				}
				.box{ 
					padding: 30px 50px;
					margin: -47px 15px 0;
					a{ 
						display: block;
						text-decoration: none;
						.category{ 
							.catName{ 
								min-width: 88px;
								font-size: 1.3rem;
								margin-left: 15px;
							}
						}
						.titColumn{ 
							font-size: 2.0rem;
							border-bottom: 1px solid #d6d6d6;
							padding-bottom: 15px;
							margin-bottom: 15px;
						}
						&:hover{ 
							.titColumn{ 
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
		.btn{ 
			width: 410px;
			margin: auto;
			padding: 0;
		}
	}
}

/*access
-------------------------------------------------------------*/
.secAccess{ 
	padding: 40px 0 0;
	.head{ 
		padding-top: 40px;
		background: url(../img/home/ico_tit_access01.png) 50% 0 no-repeat;
		background-size: 37px auto;
		margin-bottom: 20px;
	}
	.mapWrap{ 
		margin: 0 -15px;
	}
	.map{ 
		position:relative;
		width:100%;
		height:174px;
		iframe{ 
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.box{ 
		background-color: #69412b;
		margin: 0 -15px;
		padding: 0 15px 50px;
		.boxIn{ 
			box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.3);
			margin-top: -25px;
			background-color: #fff;
			position: relative;
			padding: 40px 20px 30px;
			&:before{ 
				content: '';
				background: url(../img/home/bg_access01.png) 0 0 no-repeat;
				background-size: contain;
				width: 135px;
				height: 70px;
				position: absolute;
				top: -12px;
				left: -5px;
			}
			.logo{ 
				margin-bottom: 25px;
			}
			.txt{ 
				margin-bottom: 15px;
			}
			.link{ 
				margin-bottom: 30px;
				a{ 
					color: #257e61;
					position: relative;
					padding-right: 20px;
					&:before,
					&:after{ 
						position: absolute;
						right: 0;
					}
					&:before{ 
						content: '';
						width: 12px;
						height: 12px;
						border-radius: 50%;
						background: #257e61;
						top: 7px;
					}
					&:after{ 
						content: '';
						box-sizing: border-box;
						width: 3px;
						height: 3px;
						border: 3px solid transparent;
						border-left: 5px solid #fff;
						top: 10px;
					}
				}
			}
		}
	}
	@include mq(pc) { 
		padding: 70px 0 0;
		.head{ 
			padding-top: 50px;
			background: url(../img/home/ico_tit_access01.png) 50% 0 no-repeat;
			background-size: 44px auto;
			margin-bottom: 30px;
		}
		.mapWrap{ 
			margin: 0 0 -305px;
		}
		.map{ ;
			height:509px;
		}
		.box{ 
			margin: 0 -100%;
			padding: 195px 100% 80px;
			.boxIn{ 
				margin-top: 0;
				padding: 50px 0 55px;
				margin: 0 90px;
				&:before{ 
					content: '';
					background: url(../img/home/bg_access01.png) 0 0 no-repeat;
					background-size: contain;
					width: 338px;
					height: 175px;
					position: absolute;
					top: -30px;
					left: -23px;
				}
				.logo{ 
					width: 428px;
					margin: 0 auto 25px;
				}
				.txt{ 
					text-align: center;
					margin-bottom: 15px;
					line-height: 1.8;
				}
				.link{ 
					text-align: center;
					margin-bottom: 33px;
					a{ 
						font-size: 1.6rem;
						padding-right: 27px;
						&:before{ 
							width: 16px;
							height: 16px;
							top: 6px;
						}
						&:after{ 
							width: 4px;
							height: 4px;
							border: 4px solid transparent;
							border-left: 6px solid #fff;
							top: 10px;
						}
					}
				}
				.btn{ 
					width: 410px;
					margin: auto;
				}
			}
		}
	}
}

