@charset "UTF-8";
/*=============================================================
 home トップページ
=============================================================*/
/*service
-------------------------------------------------------------*/
.secService {  background-color: #f6faf5; padding: 40px 0; }

.secService .head {  padding-top: 40px; background: url(../img/home/ico_tit_service01.png) 50% 0 no-repeat; background-size: 43px auto; }

.secService .read {  text-align: center; font-size: 1.6rem; line-height: 1.7; padding: 15px 0 25px; }

.secService .list {  margin-bottom: 25px; }

.secService .list .item {  background-color: #fff; }

.secService .list .item + .item {  margin-top: 15px; }

.secService .list .item .box {  border-top: 3px solid #257e61; padding: 0 20px 20px; }

.secService .list .item .box .titItem {  text-align: center; font-size: 1.9rem; font-weight: 500; color: #257e61; border-bottom: 1px solid #d9d8cf; padding: 10px 0 8px; }

.secService .list .item .box .txt {  padding-top: 10px; }

.secService .btn {  padding: 0 20px; box-sizing: border-box; }

@media all and (min-width: 600px) {  .secService {  padding: 67px 0 70px; }
  .secService .head {  padding-top: 60px; background: url(../img/home/ico_tit_service01.png) 50% 0 no-repeat; background-size: 61px; }
  .secService .read {  font-size: 1.7rem; padding: 20px 0 30px; }
  .secService .list {  margin-bottom: 30px; }
  .secService .list .item {  margin-bottom: 20px; float: left; width: 353px; margin-right: 20px; }
  .secService .list .item + .item {  margin-top: 0; }
  .secService .list .item:nth-child(3n) {  margin-right: 0; }
  .secService .list .item .box {  padding: 0 30px 30px; }
  .secService .list .item .box .titItem {  font-size: 2.3rem; padding: 20px 0 12px; }
  .secService .list .item .box .txt {  padding-top: 15px; }
  .secService .btn {  width: 410px; margin: auto; padding: 0; } }

/*features
-------------------------------------------------------------*/
.secFeatures {  background-color: #0f6b4d; padding: 40px 0; }

.secFeatures .head .read {  font-size: 1.7rem; background-color: #fff; text-align: center; border-radius: 25px; font-weight: 500; padding: 5px 0; position: relative; width: 275px; margin: 0 auto 8px; }

.secFeatures .head .read:before {  content: ''; width: 0; height: 0; border-style: solid; border-width: 7px 7px 0 7px; border-color: #fff transparent transparent transparent; position: absolute; bottom: -7px; left: 0; right: 0; margin: auto; }

.secFeatures .head .tit {  text-align: center; margin-bottom: 10px; }

.secFeatures .head .tit .titIn {  color: #fff; font-size: 2.9rem; font-weight: 500; background-color: #0f6b4d; }

.secFeatures .head .tit .titIn .num {  display: inline-block; font-size: 5.4rem; font-weight: 500; line-height: 1.2; }

.secFeatures .head .tit:before {  background-color: #579883; }

.secFeatures .list .item {  display: table; width: 100%; }

.secFeatures .list .item + .item {  margin-top: 15px; }

.secFeatures .list .item .img {  display: table-cell; vertical-align: middle; width: 176px; }

.secFeatures .list .item .txt {  display: table-cell; vertical-align: middle; color: #fff; padding-left: 15px; }

@media all and (min-width: 600px) {  .secFeatures {  background: #0f6b4d url(../img/home/bg_service01.png) 100% 0 no-repeat; padding: 70px 0 90px; }
  .secFeatures .head .read {  font-size: 2.1rem; padding: 10px 0; width: 550px; margin-bottom: 10px; }
  .secFeatures .head .read:before {  border-width: 10px 10px 0 10px; bottom: -10px; }
  .secFeatures .head .tit {  margin-bottom: 25px; }
  .secFeatures .head .tit .titIn {  font-size: 4.0rem; }
  .secFeatures .head .tit .titIn .num {  font-size: 9.0rem; line-height: 1.1; }
  .secFeatures .list {  margin-left: 30px; }
  .secFeatures .list .item {  float: left; width: 303px; }
  .secFeatures .list .item + .item {  margin-top: 0; margin-left: 80px; }
  .secFeatures .list .item .img {  display: block; width: 295px; margin-left: auto; margin-bottom: 5px; }
  .secFeatures .list .item .txt {  display: block; padding-left: 0; padding-right: 40px; } }

/*message
-------------------------------------------------------------*/
.secMessage {  box-shadow: 0px 2px 3.92px 0.08px rgba(215, 215, 215, 0.49); }

.secMessage .img {  margin: 0 -15px; }

.secMessage .box {  padding: 35px 15px 25px; }

.secMessage .box .head {  background: url(../img/home/ico_tit_message01.png) 6px 0 no-repeat; background-size: 30px auto; padding-left: 50px; margin-bottom: 3px; }

.secMessage .box .head .tit {  font-size: 2.3rem; line-height: 1; margin-bottom: 7px; font-weight: 500; }

.secMessage .box .head .eng {  text-align: left; }

@media all and (min-width: 600px) {  .secMessage {  box-shadow: none; background: url(../img/home/bg_message01.png) 50% 50% no-repeat; background-size: cover; padding: 80px 0 70px; }
  .secMessage .relative {  position: relative; }
  .secMessage .img {  margin: 0; position: absolute; bottom: -70px; right: -40px; }
  .secMessage .box {  width: 685px; background-color: #fff; padding: 55px 60px 60px; box-sizing: border-box; }
  .secMessage .box .head {  background: url(../img/home/ico_tit_message01.png) 6px 5px no-repeat; background-size: 37px auto; padding-left: 60px; margin-bottom: 20px; }
  .secMessage .box .head .tit {  font-size: 3.2rem; margin-bottom: 5px; } }

/*new
-------------------------------------------------------------*/
.secNew {  padding: 35px 0; }

.secNew .head .tit {  font-size: 2.2rem; line-height: 1; margin-bottom: 7px; font-weight: 500; }

.secNew .head .eng {  text-align: left; }

.secNew .list {  margin-bottom: 25px; }

.secNew .list .item {  border-bottom: 1px solid #e0e0e0; margin: 0 -15px; padding: 0 15px; }

.secNew .list .item a {  display: block; text-decoration: none; padding: 18px 0; }

.secNew .list .item a .date {  color: #918674; }

.secNew .list .item a .date:before {  content: ''; background-color: #918674; width: 10px; height: 10px; border-radius: 2px; display: inline-block; margin-right: 6px; }

.secNew .list .item a .txt {  color: #257e61; font-weight: 500; }

.secNew .btn {  padding: 0 20px; box-sizing: border-box; }

@media all and (min-width: 600px) {  .secNew {  padding: 40px 0; }
  .secNew .relative {  position: relative; }
  .secNew .head {  float: left; width: 260px; padding-top: 25px; }
  .secNew .head .tit {  font-size: 3.0rem; margin-bottom: 5px; }
  .secNew .list {  float: right; width: 840px; margin-bottom: 50px; }
  .secNew .list .item {  border-bottom: 1px solid #e0e0e0; margin: 0; padding: 0; }
  .secNew .list .item a {  padding: 25px 0; }
  .secNew .list .item a .date {  float: left; width: 130px; }
  .secNew .list .item a .txt {  padding-left: 130px; }
  .secNew .list .item a:hover .txt {  text-decoration: underline; }
  .secNew .btn {  width: 410px; margin: auto; padding: 0; } }

/*column
-------------------------------------------------------------*/
.secColumn {  background-color: #f7fbf6; padding: 44px 0; }

.secColumn .head {  padding-top: 40px; background: url(../img/home/ico_tit_column01.png) 50% 0 no-repeat; background-size: 48px auto; margin-bottom: 20px; }

.secColumn .list {  margin-bottom: 25px; }

.secColumn .list .item + .item {  margin-top: 20px; }

.secColumn .list .item .img {  margin: 0 -15px; }

.secColumn .list .item .box {  border-top: 3px solid #257e61; box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.3); padding: 25px 20px 15px; margin-top: -25px; position: relative; background-color: #fff; }

.secColumn .list .item .box a {  display: block; text-decoration: none; }

.secColumn .list .item .box a .category {  margin-bottom: 10px; }

.secColumn .list .item .box a .category .date {  color: #918674; }

.secColumn .list .item .box a .category .date:before {  content: ''; background-color: #918674; width: 10px; height: 10px; border-radius: 2px; display: inline-block; margin-right: 6px; }

.secColumn .list .item .box a .category .catName {  display: inline-block; min-width: 90px; background-color: #257e61; color: #fff; font-size: 1.3rem; font-weight: 500; text-align: center; margin-left: 10px; padding: 2px 5px; box-sizing: border-box; }

.secColumn .list .item .box a .titColumn {  font-size: 1.9rem; color: #257e61; font-weight: 500; }

.secColumn .btn {  padding: 0 20px; box-sizing: border-box; }

@media all and (min-width: 600px) {  .secColumn {  padding: 80px 0 70px; }
  .secColumn .head {  padding-top: 50px; background: url(../img/home/ico_tit_column01.png) 50% 0 no-repeat; background-size: 57px auto; margin-bottom: 40px; }
  .secColumn .list {  margin-bottom: 20px; }
  .secColumn .list .item {  margin-bottom: 30px; float: left; width: 536px; }
  .secColumn .list .item + .item {  margin-top: 0; }
  .secColumn .list .item:nth-child(even) {  float: right; }
  .secColumn .list .item .img {  margin: 0; }
  .secColumn .list .item .box {  padding: 30px 50px; margin: -47px 15px 0; }
  .secColumn .list .item .box a {  display: block; text-decoration: none; }
  .secColumn .list .item .box a .category .catName {  min-width: 88px; font-size: 1.3rem; margin-left: 15px; }
  .secColumn .list .item .box a .titColumn {  font-size: 2.0rem; border-bottom: 1px solid #d6d6d6; padding-bottom: 15px; margin-bottom: 15px; }
  .secColumn .list .item .box a:hover .titColumn {  text-decoration: underline; }
  .secColumn .btn {  width: 410px; margin: auto; padding: 0; } }

/*access
-------------------------------------------------------------*/
.secAccess {  padding: 40px 0 0; }

.secAccess .head {  padding-top: 40px; background: url(../img/home/ico_tit_access01.png) 50% 0 no-repeat; background-size: 37px auto; margin-bottom: 20px; }

.secAccess .mapWrap {  margin: 0 -15px; }

.secAccess .map {  position: relative; width: 100%; height: 174px; }

.secAccess .map iframe {  position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.secAccess .box {  background-color: #69412b; margin: 0 -15px; padding: 0 15px 50px; }

.secAccess .box .boxIn {  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.3); margin-top: -25px; background-color: #fff; position: relative; padding: 40px 20px 30px; }

.secAccess .box .boxIn:before {  content: ''; background: url(../img/home/bg_access01.png) 0 0 no-repeat; background-size: contain; width: 135px; height: 70px; position: absolute; top: -12px; left: -5px; }

.secAccess .box .boxIn .logo {  margin-bottom: 25px; }

.secAccess .box .boxIn .txt {  margin-bottom: 15px; }

.secAccess .box .boxIn .link {  margin-bottom: 30px; }

.secAccess .box .boxIn .link a {  color: #257e61; position: relative; padding-right: 20px; }

.secAccess .box .boxIn .link a:before, .secAccess .box .boxIn .link a:after {  position: absolute; right: 0; }

.secAccess .box .boxIn .link a:before {  content: ''; width: 12px; height: 12px; border-radius: 50%; background: #257e61; top: 7px; }

.secAccess .box .boxIn .link a:after {  content: ''; box-sizing: border-box; width: 3px; height: 3px; border: 3px solid transparent; border-left: 5px solid #fff; top: 10px; }

@media all and (min-width: 600px) {  .secAccess {  padding: 70px 0 0; }
  .secAccess .head {  padding-top: 50px; background: url(../img/home/ico_tit_access01.png) 50% 0 no-repeat; background-size: 44px auto; margin-bottom: 30px; }
  .secAccess .mapWrap {  margin: 0 0 -305px; }
  .secAccess .map {  height: 509px; }
  .secAccess .box {  margin: 0 -100%; padding: 195px 100% 80px; }
  .secAccess .box .boxIn {  margin-top: 0; padding: 50px 0 55px; margin: 0 90px; }
  .secAccess .box .boxIn:before {  content: ''; background: url(../img/home/bg_access01.png) 0 0 no-repeat; background-size: contain; width: 338px; height: 175px; position: absolute; top: -30px; left: -23px; }
  .secAccess .box .boxIn .logo {  width: 428px; margin: 0 auto 25px; }
  .secAccess .box .boxIn .txt {  text-align: center; margin-bottom: 15px; line-height: 1.8; }
  .secAccess .box .boxIn .link {  text-align: center; margin-bottom: 33px; }
  .secAccess .box .boxIn .link a {  font-size: 1.6rem; padding-right: 27px; }
  .secAccess .box .boxIn .link a:before {  width: 16px; height: 16px; top: 6px; }
  .secAccess .box .boxIn .link a:after {  width: 4px; height: 4px; border: 4px solid transparent; border-left: 6px solid #fff; top: 10px; }
  .secAccess .box .boxIn .btn {  width: 410px; margin: auto; } }
